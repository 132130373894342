import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMedicines,
  createMedicine,
  deleteMedicines,
  associateMedicine,
  getCommercialMedicines,
  deleteCommercialMedicines,
  getDrugImages,
  getDrugImage,
  approveDrugImage,
} from 'api/medicines';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from 'features/user/userSlice';
import { errorHandler } from 'helpers/errorHandler';
import { showToast } from 'helpers/showToast';

export const medicinesState = {
  list: [],
  selected: {
    brands: [],
    error: false,
    loading: false,
  },
  loading: false,
  error: false,
  drugs: {
    next: null,
    prev: null,
    list: [],
    current: {},
  },
};

const fetchMedicines = createAsyncThunk(
  'medicines/fetchMedicines',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getMedicines,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data, medicinesState.list);
  },
);

const postMedicine = createAsyncThunk(
  'medicines/postMedicines',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      createMedicine,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

const postCommercialMedicine = createAsyncThunk(
  'medicines/commercial/postCommercialMedicines',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      associateMedicine,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

const fetchCommercialMedicine = createAsyncThunk(
  'medicines/commercial/fetchCommercialMedicine',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getCommercialMedicines,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

const deleteMedicine = createAsyncThunk(
  'medicines/deleteMedicines',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      deleteMedicines,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

const deleteCommercialMedicine = createAsyncThunk(
  'medicines/deleteCommercialMedicine',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      deleteCommercialMedicines,
      payload.brandId,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    return errorHandler(response.status, response.data);
  },
);

export const fetchDrugImages = createAsyncThunk(
  'medicines/fetchDrugImages',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;

    const response = await thunkhandler(
      getDrugImages,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );

    const images = response.data.results.map(async (medicine) => {
      const { data } = await thunkhandler(
        getDrugImage,
        { id: medicine.id },
        thunkAPI,
        refreshSessionThunk,
        token,
      );

      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = data && data !== 503 && urlCreator.createObjectURL(data);
      return imageUrl;
    });

    const resolvedImages = await Promise.all(images);

    const results = response.data.results.map((medicine, index) => ({
      ...medicine,
      imageUrl: resolvedImages[index] || null,
    }));

    return {
      prev: response.data.previous,
      next: response.data.next,
      results,
    };
  },
);

const patchDrugInformation = createAsyncThunk(
  'medicines/patchDrugInformation',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      approveDrugImage,
      payload,
      thunkAPI,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data);
  },
);

const medicinesSlice = createSlice({
  name: 'medicines',
  initialState: medicinesState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchMedicines.fulfilled, (state, action) => {
        state.list = action.payload;
        state.error = false;
      })
      .addCase(fetchMedicines.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(postMedicine.fulfilled, (state, action) => {
        showToast('Se añadió el medicamento exitosamente', 'is-success');
        state.list.push(action.payload);
      })
      .addCase(deleteMedicine.fulfilled, (state, action) => {
        showToast('Se elimino un medicamento exitosamente', 'is-success');
        state.list = state.list.filter((med) => med.id !== action.payload.id);
      })
      .addCase(fetchCommercialMedicine.fulfilled, (state, action) => {
        state.selected.brands = action.payload;
        state.selected.error = false;
      })
      .addCase(fetchCommercialMedicine.rejected, (state, action) => {
        state.selected.error = true;
      })
      .addCase(postCommercialMedicine.fulfilled, (state, action) => {
        showToast('Asociación creada existosamente');
        state.selected.brands = [...state.selected.brands, action.payload];
      })
      .addCase(deleteCommercialMedicine.fulfilled, (state, action) => {
        showToast('Asociación eliminada exitosamente');
        state.selected.brands = state.selected.brands.filter(
          (item) => item.id !== action.payload.id,
        );
      })
      .addCase(fetchDrugImages.fulfilled, (state, action) => {
        const { prev, next, results } = action.payload;
        state.drugs.prev = prev ? true : null;
        state.drugs.next = next ? true : null;
        state.drugs.list = results;
        state.drugs.current = {};
      })
      .addCase(patchDrugInformation.fulfilled, (state, action) => {
        showToast('Medicamento aprobado por administrador', 'is-success');
        state.drugs.list = state.drugs.list.filter(
          (item) => item.id !== action.payload.id,
        );
      });
  },
});

export const fetchMedicinesThunk = fetchMedicines;
export const postMedicineThunk = postMedicine;
export const deleteMedicineThunk = deleteMedicine;
export const deleteCommercialMedicineThunk = deleteCommercialMedicine;
export const fetchDrugImagesThunk = fetchDrugImages;
export const patchDrugInformationThunk = patchDrugInformation;
export const postCommercialMedicineThunk = postCommercialMedicine;
export const fetchCommercialMedicineThunk = fetchCommercialMedicine;
export const medicinesReducer = medicinesSlice.reducer;
