import http from '../http';
export async function getMedicines() {
  return await http.get('drugs');
}

export async function createMedicine(payload) {
  return await http.post('drugs', payload);
}

export async function deleteMedicines(payload) {
  const path = `drugs/${payload}`;
  return await http.delete(path);
}

export async function associateMedicine(payload) {
  return await http.post('drugs/commercial', payload);
}

export async function getCommercialMedicines(payload) {
  const path = `drugs/${payload}/commercial`;
  return await http.get(path);
}

export async function deleteCommercialMedicines(payload) {
  const path = `drugs/commercial/${payload}`;
  return await http.delete(path);
}

export async function getDrugImages(payload) {
  const { page } = payload;
  const response = await http.get(`drug-requests`, {
    params: {
      page,
    },
  });
  return response;
}

export async function getDrugImage(payload) {
  const { id } = payload;
  const response = await http.get(`drug-requests/${id}/image`, {
    blob: true,
  });
  return response;
}

export async function approveDrugImage(payload) {
  const {
    id,
    active_ingredient,
    dosage,
    unit,
    route_of_administration,
    presentation,
    family,
    brand_name,
  } = payload;

  const params = {
    active_ingredient,
    dosage,
    unit,
    route_of_administration,
    presentation,
    family,
    brand_name,
  };

  const response = await http.post(`drug-requests/${id}/confirm`, params);
  return response;
}
